import PropTypes from 'prop-types';
import React from 'react';
import './../styles/global.scss';
import Footer from './container/footer';
import Navigation from './container/navigation';

const Layout = ({ children }) => {
  return (
    <>
      <Navigation />
      <main>{children}</main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
