export const navigationLinks = [
  {
    name: 'About',
    navLink: 'section-header'
  },
  {
    name: 'Skill',
    navLink: 'section-skill'
  },
  {
    name: 'Experience',
    navLink: 'section-experience'
  },
  {
    name: 'Contact',
    navLink: 'section-contact'
  }
];

export const ReactScrollConfig = {
  SPY: true,
  SMOOTH: true,
  OFFSET: -56,
  DURATION: 500,
  DYNAMIC: true
};
