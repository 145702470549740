import React from 'react';

export default function Footer() {
  return (
    <footer className='footer'>
      {/* <!-- Copyright Section--> */}
      <div className='container container--stretch'>
        <div className='copyright text-center text-white'>
          Copyright © {new Date().getFullYear()} Digbijaya Shakya. All rights
          reserved.
        </div>
      </div>
    </footer>
  );
}
