import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import { Link } from 'react-scroll';
import { navigationLinks, ReactScrollConfig } from '../../utils/constant';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { SPY, SMOOTH, OFFSET, DURATION, DYNAMIC } = ReactScrollConfig;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const hideMenuOnNavigation = () => {
    setIsOpen(false);
  };

  return (
    <nav
      className='navbar navbar-expand-lg text-uppercase fixed-top'
      id='mainNav'>
      <div className='container-fluid'>
        <Button
          className='navbar-toggler text-uppercase font-weight-bold rounded'
          type='button'
          aria-label='Menu'
          aria-controls='navbarResponsive'
          aria-expanded={isOpen}
          onClick={toggleMenu}>
          <FontAwesomeIcon icon={['fas', 'bars']} size='lg' />
        </Button>

        <Link
          className='navbar-brand'
          activeClass='active'
          to='section-header'
          spy={SPY}
          smooth={SMOOTH}
          offset={OFFSET}
          duration={DURATION}
          isDynamic={DYNAMIC}
          onClick={hideMenuOnNavigation}>
          Digbijaya Shakya
        </Link>

        <Collapse in={isOpen}>
          <div className='navbar-collapse' id='navbarResponsive'>
            <ul className='navbar-nav ml-auto'>
              {navigationLinks.map((navItem, i) => {
                return (
                  <li key={`nav-item-${i}`} className='nav-item mx-0 mx-lg-1'>
                    <Link
                      className='nav-link py-3 px-0 px-lg-3'
                      activeClass='active'
                      to={navItem.navLink}
                      spy={SPY}
                      smooth={SMOOTH}
                      offset={OFFSET}
                      duration={DURATION}
                      isDynamic={DYNAMIC}
                      onClick={hideMenuOnNavigation}>
                      {navItem.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </Collapse>
      </div>
    </nav>
    // </div>
  );
};

export default Navigation;
